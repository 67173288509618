<template>
  <Modal
    :show="Ekle"
    :title="$t('Musteriler.olustur')"
    :save-text="$t('Musteriler.kaydet')"
    @close="Ekle = false"
    @saveMethod="Olustur"
  >
    <template v-slot:form>
      <v-text-field
        v-model="data.kod"
        :label="$t('Musteriler.kod')"
        type="text"
        dense
        class="mt-6"
        counter="3"
        :rules="rules"
      ></v-text-field>
      <v-text-field
        v-model="data.adi"
        :label="$t('Musteriler.adi')"
        type="text"
        dense
        class="mt-6"
      ></v-text-field>
      <v-text-field
        v-model="data.cadde_nr"
        :label="$t('Musteriler.cadde')"
        type="text"
        dense
        class="mt-6"
      ></v-text-field>
      <v-text-field
        v-model="data.postakodu_sehir"
        :label="$t('Musteriler.postakodu')"
        type="text"
        dense
        class="mt-6"
      ></v-text-field>
      <v-text-field
        v-model="data.ulke"
        :label="$t('Musteriler.ulke')"
        type="text"
        dense
        class="mt-6"
      ></v-text-field>
    </template>
  </Modal>
</template>

<script>
import { mdiWindowClose } from '@mdi/js'
import Modal from '@/components/Modal'

export default {
  name: 'MusteriOlustur',
  components: { Modal },
  data() {
    return {
      rules: [v => v.length <= 3 || this.$t('Musteriler.EN_FAZLA_3_KARAKTER')],
      data: {
        kod: '',
        adi: '',
        cadde_nr: '',
        postakodu_sehir: '',
        ulke: '',
      },
      icons: {
        mdiWindowClose,
      },
    }
  },
  computed: {
    Ekle: {
      get() {
        return this.$store.state.createDialog
      },
      set(bool) {
        this.$store.state.createDialog = bool
      },
    },
  },
  methods: {
    Olustur() {
      this.$store.dispatch('Action', { name: 'Musteriler/MusterilerOlustur', data: this.data }).then(() => {
        this.Ekle = false
        this.data = {
          kod: '',
          adi: '',
          cadde_nr: '',
          postakodu_sehir: '',
          ulke: '',
        }
      })
    },
  },

}
</script>

<style scoped>

</style>
