<template>
  <v-dialog
    v-model="show"
    transition="dialog-top-transition"
    max-width="600"
    persistent
  >
    <template v-slot:default>
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          {{ old_data.adi }}
          <v-spacer />
          <v-btn
            icon
            color="grey"
            @click="$emit('close')"
          >
            <v-icon>{{ icons.mdiWindowClose }}</v-icon>
          </v-btn>
          <template v-slot:extension>
            <v-tabs
              v-model="tab"
              align-with-title
              active-class="active"
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.id"
                :disabled="adres_update"
              >
                {{ tab.name }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-text-field
                v-model="data.kod"
                :label="$t('Musteriler.kod')"
                type="text"
                dense
                class="mt-6"
                autofocus
                counter="3"
                :rules="rules"
              ></v-text-field>
              <v-text-field
                v-model="data.adi"
                :label="$t('Musteriler.adi')"
                type="text"
                dense
                class="mt-6"
              ></v-text-field>
              <v-text-field
                v-model="data.cadde_nr"
                :label="$t('Musteriler.cadde')"
                type="text"
                dense
                class="mt-6"
              ></v-text-field>
              <v-text-field
                v-model="data.postakodu_sehir"
                :label="$t('Musteriler.postakodu')"
                type="text"
                dense
                class="mt-6"
              ></v-text-field>
              <v-text-field
                v-model="data.ulke"
                :label="$t('Musteriler.ulke')"
                type="text"
                dense
                class="mt-6"
              ></v-text-field>

              <v-autocomplete
                v-model="data.ucretlendirme_grup_id"
                :items="UcretGruplari"
                item-value="id"
                :filter="filter"
                item-text="adi"
                dense
                :label="$t('Musteriler.ucretlendirme_grup_id')"
                class="mt-4"
              ></v-autocomplete>
              <div
                class="justify-center align-center"
                style="display: flex"
              >
                <v-btn
                  color="primary"
                  @click="Guncelle"
                >
                  {{ $t('Musteriler.kaydet') }}
                </v-btn>
              </div>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <v-data-table
                  :headers="sutunlar"
                  :items="MusteriAdresleri"
                >
                  <template v-slot:item.islem="{ item }">
                    <v-btn
                      icon
                      color="primary"
                      @click="AdresUpdate(item)"
                    >
                      <v-icon>{{ icons.mdiPencil }}</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-text-field
                v-model="adres.cadde_nr"
                :label="$t('Musteriler.cadde')"
                type="text"
                dense
                class="mt-6"
              ></v-text-field>
              <v-text-field
                v-model="adres.postakodu_sehir"
                :label="$t('Musteriler.postakodu')"
                type="text"
                dense
                class="mt-6"
              ></v-text-field>
              <v-text-field
                v-model="adres.ulke"
                :label="$t('Musteriler.ulke')"
                type="text"
                dense
                class="mt-6"
              ></v-text-field>
              <div v-if="adres_update">
                <v-checkbox
                  v-model="adres.status"
                  :label="$t('Musteriler.status')"
                  false-value="0"
                  true-value="1"
                ></v-checkbox>
                <v-checkbox
                  v-model="adres.default"
                  false-value="0"
                  true-value="1"
                  :label="$t('Musteriler.default')"
                ></v-checkbox>
              </div>
              <div
                class="justify-center align-center"
                style="display: flex"
              >
                <v-btn
                  v-if="adres_update"
                  color="secondary"
                  class="mr-5"
                  @click="AdresGuncellemeIptal"
                >
                  {{ $t('Musteriler.Iptal') }}
                </v-btn>
                <v-btn
                  v-if="adres_update"
                  color="primary"
                  class="mr-5"
                  @click="AdresGuncelle"
                >
                  {{ $t('Musteriler.Guncelle') }}
                </v-btn>

                <v-btn
                  v-if="!adres_update"
                  color="primary"
                  @click="AdresEkle"
                >
                  {{ $t('Musteriler.kaydet') }}
                </v-btn>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import {
  mdiWindowClose, mdiPencil, mdiTrashCanOutline,
} from '@mdi/js'
import api from '@/boot/axios'

export default {
  name: 'MusteriGuncelle',
  props: ['editdata'],
  data() {
    return {
      icons: {
        mdiPencil,
        mdiTrashCanOutline,
        mdiWindowClose,
      },
      show: true,
      rules: [v => v.length <= 3 || this.$t('Musteriler.EN_FAZLA_3_KARAKTER')],
      sutunlar: [
        {
          text: this.$t('Musteriler.islem'),
          align: 'start',
          filterable: false,
          value: 'islem',
        },
        {
          text: this.$t('Musteriler.cadde'),
          value: 'cadde_nr',
        },
        {
          text: this.$t('Musteriler.postakodu'),
          value: 'postakodu_sehir',
        },
        {
          text: this.$t('Musteriler.ulke'),
          value: 'ulke',
        },

      ],
      tab: null,
      tabs: [
        { id: 1, name: this.$t('Musteriler.Musteri') },
        { id: 2, name: this.$t('Musteriler.Subeler') },
        { id: 3, name: this.$t('Musteriler.SubeEkle') },
      ],
      adres: {
        musteri_id: '',
        cadde_nr: '',
        postakodu_sehir: '',
        ulke: '',
      },
      data: {
        id: '',
        kod: '',
        adi: '',
        cadde_nr: '',
        postakodu_sehir: '',
        ulke: '',
        ucretlendirme_grup_id: 0,
      },
      old_data: {},
      adres_update: false,

      UcretGruplari: [],
    }
  },
  computed: {
    MusteriAdresleri() {
      return this.$store.state.Musteriler.MusteriAdresleri.filter(adres => adres.default === '0')
    },
  },
  mounted() {
    const veri = { ...this.editdata }
    this.getUcretlendirmeGruplar()
    this.$store.dispatch('Action', { name: 'Musteriler/MusteriAdresleri', data: veri })
    this.old_data = veri
    this.data.id = veri.id
    this.adres.musteri_id = veri.id
    this.data.kod = veri.kod
    this.data.adi = veri.adi
    this.data.adres_id = veri.adresID
    this.data.cadde_nr = veri.cadde_nr
    this.data.postakodu_sehir = veri.postakodu_sehir
    this.data.ulke = veri.ulke
    this.data.ucretlendirme_grup_id = veri.ucretlendirme_grup_id

    console.log('veri',veri)
  },
  methods: {
    async getUcretlendirmeGruplar() {
      const { data } = await api.get('ucretlendirme-gruplari')
      this.UcretGruplari = data.result
    },
    filter(item, queryText, itemText) {
      queryText = queryText.replace(/[İı]/g, 'i')
      itemText = itemText.replace(/[İı]/g, 'i')

      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    AdresUpdate(item) {
      this.adres_update = true
      this.tab = 2
      this.adres = item
      console.log(item)
    },
    AdresGuncellemeIptal() {
      this.tab = 1
      this.adres_update = false
      this.adres.cadde_nr = ''
      this.adres.postakodu_sehir = ''
      this.adres.ulke = ''
      this.adres.status = ''
      this.adres.default = ''
    },
    Guncelle(event) {
      event.preventDefault()
      this.$store.dispatch('Action', { name: 'Musteriler/MusterilerGuncelle', data: this.data }).then(() => {
        this.$store.commit('PopupEkleme', false)
        this.data = {
          isim: '',
        }
        this.$store.dispatch('Action', { name: 'Musteriler/MusterilerListesi' })
        this.$emit('close')
      }).catch(() => {
        setTimeout(() => {
          this.$store.dispatch('Action', { name: 'Musteriler/MusterilerListesi' })
          this.$emit('close')
        }, 700)
      })
    },
    AdresEkle() {
      this.$store.dispatch('Action', { name: 'Musteriler/MusteriAdresleriEkle', data: this.adres }).then(() => {
        this.adres = {
          cadde_nr: '',
          postakodu_sehir: '',
          ulke: '',
        }
      })
    },
    AdresGuncelle() {
      this.$store.dispatch('Action', { name: 'Musteriler/AdresGuncelle', data: this.adres }).then(() => {
        this.adres = {
          id: '',
          cadde_nr: '',
          postakodu_sehir: '',
          ulke: '',
          status: '',
          default: '',
        }
        this.tab = 1
        this.adres_update = false
      })
    },
  },
}
</script>

<style scoped>
.active {
  color: #000000;
  background-color: rgba(0,0,0,0.1);
}
</style>
