<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('Musteriler.Musteriler') }}
        <download-excel
          :data="MusterilerListesi"
          :name="$t('Musteriler.ExcelFile')"
          :worksheet="$t('Musteriler.ExcelFile')"
          :fields="ExcelFields"
          :header="ExcelHeader"
          class="ml-3"
        >
          <img
            src="@/assets/images/misc/excel.png"
            style="width: 25px"
          />
        </download-excel>
      </v-card-title>
    </v-card>
    <v-data-table
      :headers="sutunlar"
      :items="MusterilerListesi"
      :search="arama"
      :sort-by="['id']"
      :sort-desc="[true]"
    >
      <template v-slot:item.islem="{ item }">
        <v-btn
          icon
          color="primary"
          @click="edit(item)"
        >
          <v-icon>{{ icons.mdiPencil }}</v-icon>
        </v-btn>

        <v-btn
          icon
          class="ml-3"
          color="primary"
          @click="remove(item)"
        >
          <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <musteri-guncelle
      v-if="duzenle"
      :editdata="EditItem"
      @close="duzenle=false"
    />
    <musteri-olustur />
    <silme-onayi
      :sil="sil"
      :title="silTitle"
      @Sil="MusteriSil"
    />
  </div>
</template>

<script>
import { mdiPencil, mdiTrashCanOutline, mdiMapMarker } from '@mdi/js'
import MusteriGuncelle from '@/views/musteriler/musteri-guncelle'
import MusteriOlustur from '@/views/musteriler/musteri-olustur'
import SilmeOnayi from '@/components/silme-onayi'

export default {
  name: 'Musteriler',
  components: { SilmeOnayi, MusteriOlustur, MusteriGuncelle },
  data() {
    return {
      icons: {
        mdiPencil,
        mdiTrashCanOutline,
        mdiMapMarker,
      },
      sutunlar: [
        {
          text: this.$t('Musteriler.islem'),
          align: 'start',
          filterable: false,
          value: 'islem',
        },
        {
          text: this.$t('Musteriler.id'),
          align: 'start',
          value: 'id',
        },
        {
          text: this.$t('Musteriler.kod'),
          value: 'kod',
        },
        {
          text: this.$t('Musteriler.isim'),
          value: 'adi',
        },
        {
          text: this.$t('Musteriler.cadde'),
          value: 'cadde_nr',
        },
        {
          text: this.$t('Musteriler.postakodu'),
          value: 'postakodu_sehir',
        },
        {
          text: this.$t('Musteriler.ulke'),
          value: 'ulke',
        },

      ],
      duzenle: false,
      EditItem: {},
      silItem: false,
      silTitle: false,
      sil: false,
    }
  },
  computed: {
    MusterilerListesi() {
      return this.$store.state.Musteriler.Musteriler
    },
    arama() {
      return this.$store.state.arama
    },
    ExcelFields() {
      let veri
      const fields = { }
      if (this.MusterilerListesi.length > 0) {
        veri = Object.keys(this.MusterilerListesi[0])
        veri.forEach(item => {
          // eslint-disable-next-line no-unused-vars
          const itemKey = this.$t(`Musteriler.${item}`)
          fields[itemKey] = item
        })
      }

      return fields
    },
    ExcelHeader() {
      return [this.$t('APP_NAME'), `${this.$t('tarih')}:${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`]
    },
  },
  mounted() {
    // Musteriler Listesi Çek (store/Musteriler.js)
    this.$store.dispatch('Action', { name: 'Musteriler/MusterilerListesi' })
  },
  methods: {
    MusteriSil(sonuc) {
      if (sonuc) {
        const data = { id: this.silItem.id }
        this.$store.dispatch('Action', { name: 'Musteriler/MusterilerSil', data })
      }
      this.silItem = null
      this.silTitle = null
      this.sil = false
    },
    remove(item) {
      this.silItem = item
      this.silTitle = item.adi
      this.sil = true
    },
    edit(item) {
      this.duzenle = true
      this.EditItem = item
    },
  },
}
</script>
